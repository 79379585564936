.footer-nav-wrapper {
  box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
}

.uploaded-file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;

    .up--text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 37px);
    }
  }
  button {
    color: $gray-600;
  }
}

.manage {
  &__nav {
    @extend .online-nav;
  }
  &__breadcrumbs {
    color: $gray-400;
    p {
      font-size: $h5-font-size;
      &.active {
        color: $white;
      }
    }
  }
  &__progressbar {
    .item {
      display: flex;
      flex-direction: column;
      width: 135px;
      &.active .num {
        background: $white;
        color: $gray-900;
      }
      &:last-child {
        width: auto;
        .num:after {
          display: none;
        }
      }
      &.done .num {
        position: relative;
        background: $green;

        &:before {
          content: '';
          position: absolute;
          background: $green;
          border-radius: 50%;
          background-image: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6236 1.0625L4.19703 7.93673L0.821289 4.81208' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: 12px;
          background-position: center;
          left: 0px;
          top: 0px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .num {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 4px;
      width: 22px;
      height: 22px;
      padding: 0px 2px;
      border: 1px solid $white;
      border-radius: 50%;
      text-align: center;
      position: relative;
      font-size: $small-font-size;
      &:after {
        content: '';
        height: 1px;
        width: 113px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 21px;
      }
    }
  }
}
.manage__form {
  .online__form {
    &--content {
      min-height: calc(100vh - 80px - 63px - 6px);

      &.preview {
        min-height: calc(100vh - 58px - 63px - 69px);
      }
    }
  }
}
.added-phases {
  position: relative;
  &__title:after {
    content: '';
    position: absolute;
    top: 8px;
    right: 0;
    left: 105px;
    height: 1px;
    background-color: $bg-color;
  }
  .suggested-roles__title:after {
    @extend .added-phases__title;
    left: 113px;
  }
  .added-roles__title:after {
    @extend .added-phases__title;
    left: 90px;
  }
}
.phases-list-wrapper {
  .phases-list {
    border: 1px solid $gray-100;
    .dropdown-toggle {
      padding: 0;
      &::after {
        content: '';
        display: none;
      }
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
.suggested-roles {
  &-section {
    min-height: calc(100vh - 94px - 63px - 6px);
  }
  &-wrapper {
    padding: 1rem 0;
    i {
      font-size: $font-size-lg;
    }
  }
  &__detail {
    p {
      font-size: $h6-font-size;
      margin-bottom: 0.5rem;
    }
  }
}
.added-roles {
  &-wrapper {
    .dropdown-toggle {
      padding: 0;
      &::after {
        content: '';
        display: none;
      }
    }
  }
  &__btn {
    border: 1px dashed $gray-300;
    font-size: $h5-font-size;
  }
  &__title {
    color: $gray-600;
  }
  &__detail {
    i {
      border-radius: 2px;
    }
  }
  &-nav {
    &__link {
      padding: 1rem;
    }
    .active {
      background-color: $white !important;
      border: 1px solid $primary-light-2;
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
      .roles-dropdown {
        display: inline-block;
      }
      .added-roles__title {
        color: $primary-dark-1;
        font-weight: $font-weight-bold;
      }
    }
    .show > .nav-link {
      color: $primary-dark-2;
      background-color: $white;
    }
    .roles-dropdown {
      display: none;
      .nav-link {
        margin-top: 4px;
      }
    }
  }
}
.added-resources {
  &-wrapper {
    border: 1px solid $bg-color;
    padding: 8px;
    @extend .added-roles-wrapper;
    .dropdown-toggle i {
      color: $gray-600;
      background-color: $white;
    }
    i {
      background-color: $accent-100;
      color: $accent-400;
    }
  }
  &__detail {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 0;
      color: $gray-600;
    }
  }
}
.resource-timeline {
  border-left: 2px solid $gray-300;
}
.add-resources-modal {
  &__description {
    color: $gray-600;
  }
  .label-working-hours {
    font-size: 14px;
  }
}
.no-dropdown {
  .dropdown-toggle {
    padding: 0;
    &::after {
      content: '';
      display: none;
    }
  }
}
.project-overview {
  &-wrapper {
    margin-top: 20px;
    .right-border {
      border-right: 1px solid $gray-300;
    }
  }
  &__detail {
    margin-left: 30px;
    width: calc(100% - 144px);
  }
  &__name {
    h4 {
      font-size: $font-size-lg;
    }
  }
  &__text {
    p {
      font-size: $font-size-lg;
      margin-bottom: 0;
      &:first-child {
        color: $gray-500;
      }
    }
  }
}
@include only-for-mobile {
  .manage__form {
    .online__form {
      &--content {
        min-height: calc(100vh - 132px - 63px - 6px);
      }
    }
  }
  .suggested-roles {
    &-section {
      min-height: calc(100vh - 150px - 63px - 6px);
    }
  }
  .added-roles {
    &-wrapper {
      > i {
        color: $primary-dark-2;
      }
    }
    &__detail i {
      margin-bottom: -3px;
    }
    &__title {
      color: $gray-600;
    }
    &__accordion {
      .card {
        overflow: visible;
        border: 0;
        &.active {
          border: 1px solid $gray-100;
          border-radius: 4px;
          .added-roles__title {
            font-weight: 600;
            color: $primary-dark-1;
          }
        }
      }
      .card-header {
        padding: 16px;
        border: 0;
        background-color: $white;
      }
      .dropdown-toggle {
        color: $primary-dark-2;
      }
    }
  }
  .added-resources {
    &-wrapper {
      margin-top: 1rem;
    }
    &__detail {
      flex-direction: row;
      p {
        font-size: 11px;
        margin-right: 1rem;
      }
    }
  }
}
