.squad {
  &__block {
    width: calc(100% - 106px);
  }

  &-title {
    font-weight: 600;
    color: $primary;
    margin: 0;
    @include text-ellipsis(0px);
  }

  &__progressbar {
    margin: 5px 0 12px;
    .progress-bar {
      // position: relative;
      .squad-type {
        position: absolute;
        bottom: 2px;
        color: $gray-900 !important;

        padding-left: 16px;
        &:after {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          background-color: currentColor;
          left: 0;
          top: -6px;
        }
        &-blue:after {
          background-color: $blue;
        }
        &-yellow:after {
          background-color: $warning;
        }
      }
    }
  }

  &--resources {
    display: inline-block;
    vertical-align: middle;
    padding: 8px 8px;
    border-radius: 8px;
    background-color: $bg-color;
    border: 1px solid $bg-color;
    min-width: 274px;
    margin-right: 15px;

    .resources-block {
      display: inline-block;
      vertical-align: middle;
      min-width: 132px;
      font-size: $font-size-sm;

      .avatar {
        z-index: 0;
        margin-left: -10px;
        border: 1px solid $white;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

.projects--list {
  border-bottom: 1px solid $gray-400;
  padding: 12px 0;

  &:last-child {
    border: 0;
  }
}

.resource-table {
  th {
    font-size: 0.875rem;
  }
  .toggle-table {
    background: rgba(240, 237, 247, 0.5);
    .icon {
      background-color: $primary-dark-2;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      color: white;
      font-size: 12px;
      font-weight: 800;
      vertical-align: middle;
    }
    td {
      padding: 0.5rem 0.75rem;
      border-top: 1px solid #dee2e6;
    }
  }

  .collapsing {
    max-height: 0;
    @include transition-css(all, 300ms, ease-in-out);
  }

  .collapse {
    @include transition-css(all, 300ms, ease-in-out);
  }

  .collapse.show,
  .collapsing {
    display: table-row;
    max-height: 100px;
    @include transition-css(all, 300ms, ease-in-out);

    td {
      padding: 0.5rem 0.75rem !important;
      border: 1px solid #dee2e6;
    }
  }
}
