.custom {
  &-checkbox {
    position: relative;
    display: block;
    padding-left: 22px;

    .custom-control-label {
      cursor: pointer;

      &:before {
        width: 15px;
        height: 15px;
        border-radius: 2px;
      }
    }

    .custom-control-input {
      &:checked {
        & ~ .custom-control-label {
          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.16665 1L3.43748 6.83333L0.833313 4.18182' stroke='%236D49B1' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            color: $primary;
            border-color: $primary;
            background-size: 9px;
            top: 4px;
          }

          &:before {
            background: transparent;
          }
        }
      }

      &:disabled,
      &[disabled] {
        ~ .custom-control-label {
          &:before {
            border: 1px solid $gray-400;
          }
        }
      }

      &:focus {
        ~ .custom-control-label {
          &:before {
            // box-shadow: none;
            outline: 2px solid $white;
            border-radius: 0;
          }
        }
      }
    }

    &.small {
      .custom-control-label {
        padding-top: 2px;
      }
    }

    &--block {
      margin-bottom: 8px;
      padding: 0;
      border-radius: 4px;
      background: $white;
      border: 1px solid $gray-300;

      .custom-control-label {
        cursor: pointer;
        padding: 12px 25px 12px 35px;
        display: block;

        &:before {
          left: 8px;
          top: 14px;
        }
      }

      .custom-control-input {
        &:checked {
          & ~ .custom-control-label {
            &:after {
              left: 8px;
              top: 15px;
            }

            &:before {
              background: transparent;
            }
          }
        }
      }
    }
  }

  &-checkbox-block {
    margin: 0 8px 12px 0;
    overflow: hidden;
    position: relative;

    .custom-control-label {
      border-radius: 18px;
      padding: 8px 14px;
      background: $gray-300;
      cursor: pointer;

      &:before,
      &:after {
        display: none;
      }
    }
    .custom-control-input {
      opacity: 0.1;

      &:checked {
        & ~ .custom-control-label {
          background: $indigo;
          color: $white;
        }
      }

      &:disabled,
      &[disabled] {
        ~ .custom-control-label {
          background: $gray-200;
          cursor: not-allowed;
        }
      }

      &:focus {
        ~ .custom-control-label {
          &:before {
            // box-shadow: none;
            outline: 2px solid $white;
            border-radius: 0;
          }
        }
      }
    }
  }

  &-control {
    z-index: auto;
  }
}
