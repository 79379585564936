.online {
  &-nav {
    background: $primary-dark-1;
    padding: 12px 15px;
    color: $white;
    display: flex;
    align-items: center;
  }

  &--assessment {
    display: block;

    .item {
      display: inline-block;
      vertical-align: middle;

      .num {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 22px;
        height: 22px;
        padding: 0px 2px;
        border: 1px solid $white;
        border-radius: 50%;
        text-align: center;
      }

      &:after {
        content: '';
        height: 1px;
        width: 50px;
        background: $white;
        display: inline-block;
        margin: 4px 15px;
      }

      &:last-child:after {
        display: none;
      }

      &.active .num {
        background: $white;
        color: $gray-900;
      }

      &.done .num {
        position: relative;
        background: $green;

        &:after {
          content: '';
          position: absolute;
          background: $green;
          border-radius: 50%;
          background-image: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6236 1.0625L4.19703 7.93673L0.821289 4.81208' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: 12px;
          background-position: center;
          left: 0px;
          top: 0px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__form {
    padding: 12px 0 0;
    background: $bg-color;
    height: calc(100vh - 62px);

    @extend .scrollbar;

    .online__progress {
      .progress {
        height: 1px;
        background: $gray-400;
      }
    }

    &--content {
      min-height: calc(100% - 64px);
      padding-bottom: 10px;

      &.preview {
        min-height: calc(100vh - 58px - 63px - 69px);

        .h4 {
          &::first-letter {
            margin-left: -17px;
          }
          > *:first-child {
            display: inline;
          }
        }
      }
    }

    &--title {
      margin: 15px 0;
      font-weight: $font-weight-bold;
      position: relative;

      .content {
        background: $bg-color;
        padding-right: 15px;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        height: 1px;
        left: 0;
        right: 0;
        top: 9px;
        background: $gray-300;
        position: absolute;
      }
    }

    .q-list {
      padding-left: 20px;
      list-style: decimal;
      height: calc(100vh - 280px);
      @extend .scrollbar;
      margin: 0;

      .item {
        padding: 5px 0;

        .text-truncate {
          display: inline-block;
          vertical-align: middle;
          width: calc(100% - 35px);
        }

        .d-l {
          width: 30px;
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
        }

        &.active {
          color: $primary;
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}

.enlarge {
  position: relative;

  &__image {
    min-width: 60px;
    max-height: 60px;
    border-radius: 4px;
    border: 1px solid $gray-200;

    img {
      margin: 4px;
    }
  }

  &__icon {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $primary;
    background: $primary;
    text-align: center;
    top: 0;
    right: 0px;
    margin-right: -6px;
    margin-top: -6px;
    padding: 1px;

    &:hover,
    &:focus {
      background-color: $primary-dark-1;
      border-color: $primary-dark-1;
      box-shadow: none;
    }

    i {
      font-size: 16px;
      line-height: 19px;
      color: $white;
    }
  }

  &__label {
    margin-left: 10px;
    margin-bottom: 0;
    color: $primary-dark-2;
    font-weight: $font-weight-bold;
    font-family: $font-family-base;
  }

  &__question {
    text-align: center;

    img {
      max-height: 46vh;
      min-height: 46vh;
      max-width: 554px;
    }
  }
}

.image-modal {
  .modal-header {
    padding: 0px;
  }

  .modal-body {
    position: relative;

    .enlarge {
      &--label {
        font-size: 16px;
      }

      &__image {
        max-height: 65vh;
        min-height: 65vh;
        min-width: 780px;
        max-width: 780px;
        text-align: center;
        margin-bottom: 16px;
        border-radius: 8px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin: 8px;
          max-height: 60vh;
        }
      }

      &__body {
        .custom-radio {
          border: none;
        }

        .custom-checkbox--block {
          border: none;
        }

        &--angle {
          i {
            font-weight: $font-weight-bold;
            font-size: 20px;
          }
        }

        .disabled {
          color: $gray-300;
          cursor: initial;
        }
      }

      &__close {
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #5b5b60;
        background: #5b5b60;
        text-align: center;
        margin-right: 16px;

        cursor: pointer;

        &:hover,
        &:focus {
          background-color: $gray-900;
          border-color: $gray-900;
          box-shadow: none;
        }

        i {
          font-size: 16px;
          line-height: 28px;
          color: $white;
        }
      }
    }
  }
}

.skip-modal {
  .modal-header {
    padding: 0;
  }

  .title {
    font-size: $font-size-lg;
  }
}

.DayPickerInput-OverlayWrapper {
  z-index: 100;
}

.rating {
  position: absolute;
  direction: ltr;

  span {
    color: #dbdbdf;
    font-size: 18px;
    letter-spacing: 18px;
    transition: 0.2s all;
    direction: ltr;

    &:hover {
      color: #6fb989;
      background: #e0f3e2;
    }
  }
  .r1:hover ~ span {
    color: #6fb989;
    background: #e0f3e2;
  }
  .r2:hover ~ span {
    color: #6fb989;
    background: #e0f3e2;
  }
  .r3:hover ~ span {
    color: #6fb989;
    background: #e0f3e2;
  }
  .r4:hover ~ span {
    color: #6fb989;
    background: #e0f3e2;
  }
  .r5:hover ~ span {
    color: #6fb989;
    background: #e0f3e2;
  }
}

.upload-box {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  max-width: 100%;
  border: 1px dashed #c4c4c4;
  border-radius: 3px;
  padding: 0 11px;
  transition: 0.2s;

  .btn {
    position: relative;
    overflow: hidden;

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      bottom: 0;
      cursor: pointer;
    }
  }
}
.hint {
  flex: 1;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dash {
  border-bottom: 1px solid #dbdbdf;
  padding-bottom: 19px;
}
