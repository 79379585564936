.loader {
  display: block;
  position: absolute;
  padding: 12px 15px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgba($primary, 0.42);
  z-index: 9;

  img {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }
}
