.acc {
  &--container {
    min-height: calc(100vh - 70px - 50px);
  }
  &--content {
    max-width: 400px;
    margin: 50px auto;
  }
  &__block {
    border: 1px solid $gray-400;
    border-radius: 10px;
    margin: 20px 0;
    padding: 15px 15px;
    cursor: pointer;
    position: relative;
    .small {
      color: $gray-600;
    }

    .icon {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 1;
      display: none;
    }

    &:hover {
      border-color: $primary;
      color: $primary;
    }

    &.selected {
      border-color: $primary;
      color: $primary;

      .icon {
        display: block;
        color: $gray-900;
      }
    }
  }
}
