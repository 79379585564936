.integration {
  .modal {
    &-dialog {
      margin: 0 0 0 auto;
      max-width: 324px;
      height: 100vh;
      width: 100%;
    }
    &-content {
      height: 100vh;
      overflow: hidden;
      border-radius: 0;
      border: 0;
    }
    &-header {
      border: 0;

      padding {
        bottom: 0;
      }
    }
  }

  .job {
    &-sites {
      .site {
        display: flex;
        align-items: center;
        padding: 16px;
        border: 1px solid $gray-300;
        border-radius: 4px;
        margin: 8px 0;
        cursor: pointer;

        img {
          width: 28px;
          margin-right: 8px;
        }

        .h4 {
          margin: 0;
          margin-right: 20px;
        }

        &:hover {
          background: $primary-light-2;
          background: $bg-color;
        }
      }
    }
  }

  &__add {
    text-align: center;

    @include vertical-align;

    img {
      width: 57px;
      margin-bottom: 16px;
    }
  }
}
