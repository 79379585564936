// #root {
//    background: $bg-color;
// }

.main--wrapper {
  height: 100vh;
  @extend .scrollbar;
  overflow-x: hidden !important;

  .wrapper {
    height: auto;
    min-height: calc(100vh - 62px);
  }
}

.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;

  .content {
    width: 100%;
    max-height: calc(100vh - 62px);
    @extend .scrollbar;
  }
}

.mxw {
  &-3 {
    max-width: 400px;
    display: inline-flex;
  }
  &-2 {
    max-width: 290px;
    display: inline-flex;
  }
}

.text-underline {
  text-decoration: underline;
}

.close {
  font-weight: 600;
}
.pointer-event {
  pointer-events: none;
  cursor: default;
  color: #164fb1;
}
