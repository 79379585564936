@include only-for-mobile() {
  .wrapper {
    // flex-direction: column;
    // position: relative;
    // height: auto;
    // min-height: 100vh;
  }

  .main--wrapper {
    &.view--mb {
      .wrapper {
        height: auto;
        min-height: calc(100vh - 104px);

        .form--container {
          height: calc(100vh - 104px);
        }
      }
    }
  }

  @import 'create-job';
  @import 'login';

  aside {
    &.light {
      max-width: 0px;
      min-width: 0px;

      .sidebar--block .toggle-md {
        right: -18px;
        top: 0;
      }

      &.sidebar__sm {
        max-width: 0px;

        .user {
          .image--block {
            margin-right: 12px;

            img {
              max-width: 100%;
            }
          }

          &__info {
            overflow: hidden;
            max-width: 100%;
          }
        }

        .nav--item {
          .text {
            margin-left: 15px;
            max-width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }

  .top--nav {
    .nav {
      &--left {
        .logo {
          &--lg,
          &-content {
            display: none;
          }

          &--sm {
            display: block;
          }
        }
      }

      &--right {
        .dropdown-toggle {
          .user__info {
            display: none;
          }
        }
      }
    }

    &.top__sm {
      display: block;

      .nav--right {
        > span {
          display: inline-block;
          margin: 15px 0 10px;
          font-size: $font-size-base;
        }
        .form {
          &__widget {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
