.search {
  &-advance {
    min-width: 340px;
    max-width: 100%;
    padding: 15px 15px;

    .serach-button {
      border-top: 1px solid $border-color;
      padding-top: 16px;
      margin-top: 16px;
      text-align: right;
      position: relative;
      z-index: 0;
    }
  }
}
