.form {
  &--job {
    max-width: 572px;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  &--header {
    padding: 8px 0;
  }

  &--body {
    overflow: auto;
    flex: 1 1 auto;
    padding: 8px 15px;
    margin: 0 -15px;

    @extend .scrollbar;

    .integration-empty {
      margin: 36px 0;
      position: relative;

      img {
        margin: 0 0 8px 0;
      }

      &:before {
        content: '';
        position: absolute;
        top: -20px;
        right: 100px;
        background-image: url("data:image/svg+xml,%3Csvg width='82' height='63' viewBox='0 0 82 63' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.2183 56.1939C46.5111 51.485 58.9592 34.5723 64.1597 4.60377M64.1597 4.60377L73.7219 17.529M64.1597 4.60377L48.9292 12.0714' stroke='%236D49B1' stroke-width='4' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        width: 72px;
        height: 62px;
        background-size: contain;
      }
    }
    .integration-site {
      border: 1px solid $gray-100;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 20px;
      margin: 16px 0;
      cursor: pointer;
      .site {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .title {
          display: flex;
          align-items: center;
        }
        img {
          width: 28px;
          margin-right: 8px;
        }

        .h4 {
          margin: 0;
          margin-right: 20px;
        }
      }
      &__resume {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid $gray-200;
        .resume-card {
          border: 1px solid $gray-200;
          padding: 8px;
          border-radius: 8px;
          margin: 0px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          .title {
            display: flex;
            flex-direction: column;
            span:nth-child(1) {
              color: $primary;
              margin-bottom: 4px;
              font-size: $font-size-lg;
            }
          }
          &__selected {
            border: 1px solid $green;
            .icon {
              font-size: 12px;
              line-height: 12px;
              padding: 5px;
              color: $white;
              background-color: $green;
              border-radius: 50%;
            }
          }
          &:hover {
            border: 1px solid $green;
          }
        }
      }
    }
    .upload {
      display: flex;
      justify-content: space-between;
      &-resume {
        margin-top: 8px;
        padding: 36px 40px;
        border: 1px dashed $gray-300;
        background-color: $bg-color-1;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        width: 270px;
        height: 240px;
        &.dragover {
          background: lighten($primary, 40%);
          outline: 6px dashed $primary;
          outline-offset: -30px;
        }
        > input {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          cursor: pointer;
          width: 100%;
          opacity: 0;
        }
        > span {
          position: relative;
          z-index: 1;
        }
        .drag-label {
          position: relative;

          .file-type {
            font-size: $font-size-sm;
            position: absolute;
            left: 0;
            margin-top: 23px;
            margin-left: -15px;
            text-align: center;
            width: 188px;
          }
        }
        label {
          cursor: pointer;
        }
        img {
          height: 80px;
          margin-bottom: 16px;
        }
        .upload-folder {
          height: 70px;
        }
      }
    }

    .uploaded-files {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .title {
        display: flex;

        div:nth-child(1) {
          height: 24px;
          margin-top: 2px;
        }
        div:nth-child(2) {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          span:nth-child(2) {
            font-size: $font-size-sm;

            .dot {
              height: 3px;
              width: 3px;
              margin-bottom: 2px;
              border-radius: 50%;
              background-color: $text-muted;
              display: inline-block;
            }
          }
        }
      }
    }

    .uploading {
      opacity: 0.5;
    }
    .confirm-job {
      .job-title {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px 16px;
        margin-bottom: 16px;
        .title {
          display: flex;
          flex-direction: column;
        }
      }
      .resume-title {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 16px;
        border: 1px solid $gray-100;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 16px;
        .title {
          display: flex;
          flex-direction: column;
        }
      }

      .resume--item {
        padding: 16px 16px 6px 16px;
        border: 1px solid $gray-400;
        box-sizing: border-box;
        border-radius: 4px;
        margin-bottom: 16px;
      }
    }
  }

  &--footer {
    padding: 12px 0;
    display: flex;
  }
}

.resume-tabs {
  margin-top: 16px;
  .nav-tabs {
    border-bottom: 1px solid $green;
  }
  .nav-link {
    display: flex;
    color: $primary-dark-2;
    padding: 12px 30px;
    border-top: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
    border-left: 1px solid $gray-200;
    border-bottom: 1px solid $green;
    border-radius: 4px 4px 0px 0px;
    img {
      width: 28px;
      margin-right: 8px;
    }
    &.active {
      color: $primary-dark-2;
      background-color: $white;
      border-color: $green;
      border-bottom: none;
    }
    &:hover {
      color: $primary-dark-2;
      border-color: $green;
    }
  }
  .tab-content {
    padding: 24px;
    height: 100vh;
    border-right: 1px solid $green;
    border-left: 1px solid $green;
    overflow: scroll;
    @extend .scrollbar;
  }
}

.import-resumes {
  .table {
    thead th {
      border-bottom: 1px solid $primary-light-1;
    }
    tbody {
      label {
        color: $gray-800;
      }
    }
  }
}

.upload-resume-loader {
  .loader {
    &__title {
      i {
        background-color: $success;
      }
    }
    &__detail {
      color: $gray-600;
      max-width: 584px;
      margin: 0 auto;
    }
  }
}
