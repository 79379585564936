.project,
.grid {
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
    padding: 0 0 15px 0;

    .icon--block {
      display: flex;
      padding: 14px 16px;

      .body {
        width: calc(100% - 46px);
        font-weight: $font-weight-light;
        font-size: $h5-font-size;
      }
    }
  }

  &--items {
    position: relative;
    margin: 0;
    max-width: 100%;
    @include transition-css(all, 200ms, ease-in-out);
    color: inherit;

    .card {
      cursor: pointer;
      height: 100%;
      border-color: #f0edf7;
      overflow: hidden;

      &-header {
        display: flex;
        background: transparent;
        border: 0;

        .icon {
          display: none;
        }
      }

      &-footer {
        border-color: #f0edf7;
        background: transparent;

        display: flex;
        align-items: center;

        &.bg-green-light {
          background-color: $secondary-light-2;
        }

        .progress {
          height: 5px;
          width: calc(100% - 90px);
          margin-right: 10px;
        }

        .candidate--list {
          .avatar {
            z-index: 1;
            margin-left: -10px;
            border: 1px solid $white;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      .contact-info {
        width: 134px;
      }

      .email-info {
        width: calc(100% - 134px);

        .email {
          width: calc(100% - 33px);
          word-break: break-all;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: inherit;

      .card {
        border-color: $border-color;

        &-header {
          .icon {
            display: block;
          }
        }
      }
    }
  }
}
