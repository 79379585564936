// other bootstrap color overwrite
$blue: #5b90eb !default;
$indigo: #8984f0 !default;
$purple: #6f42c1 !default;
$pink: #fd71af !default;
$red: #e96965 !default;
$orange: #f48268 !default;
$yellow: #f6c750 !default;
$green: #6ec57b !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

// primary color
$primary-light-2: #c5b6e0 !default; // p01
$primary-light-1: #9980c8 !default; // p02
$primary: #6d49b1 !default; // p03
$primary-dark-1: #4c337c !default; // p04
$primary-dark-2: #2c1d47 !default; // p05

// secondary color
$secondary-light-2: #c6efeb !default; // s01
$secondary-light-1: #9ce4dc !default; // s02
$secondary: #71d8cd !default; // s03
$secondary-dark-1: #4f9790 !default; // s04
$secondary-dark-2: #2d5652 !default; // s05

// success, warning, errors, info color
$success: #6fb989 !default;
$success-light: #c5e3d0;
$info: #fbae63 !default;
$info-light: #fcefc6;
$warning: #ffc617 !default;
$warning-light: #fff4d1;
$danger: #f55b5b !default;
$danger-light: #fbbdbd;

// grey color
$white: #ffffff;
$gray-100: #f1f1f1 !default; // G01
$gray-200: #f1f1f1 !default; // G01
$gray-300: #dbdbdf !default; // G02
$gray-400: #dbdbdf !default; // G02
$gray-500: #a6a6b0 !default; // G03
$gray-600: #85858d !default; // G04
$gray-700: #85858d !default; // G04
$gray-800: #424246 !default; // G05
$gray-900: #424246 !default; // G05
$black: #000000;

$bg-color: #f0edf7;
$bg-color-1: #fbfaff;
// $gray-bg: #E5E5E5;

//accent color
$accent-100: #e3ecfc; //A01
$accent-400: #164fb1; //A04

// font weight
@import url('https://use.typekit.net/acc6ipz.css');

$font-museo-sans: museo-sans, sans-serif;
$proximaNova: proxima-nova, sans-serif;

$font-family-base: $proximaNova !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1800px,
) !default;

// font calculation in rem
$font-size-root: 14; // no units, you assume the font size base of most browsers is 16px.

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// prem stands for  px to rem.
@function prem($pxval, $base: $font-size-root) {
  @return strip-unit($pxval) / $base * 1rem;
}

// font size
$font-size-base: prem(14) !default;
$font-size-lg: prem(16) !default;
$font-size-sm: prem(12) !default;
$font-size-xs: prem(11) !default;

$h1-font-size: prem(48) !default;
$h2-font-size: prem(28) !default;
$h3-font-size: prem(22) !default;
$h4-font-size: prem(18) !default;
$h5-font-size: prem(16) !default;
$h6-font-size: prem(14) !default;

$small-font-size: prem(12) !default;
$xs-font-size: prem(10) !default;

// font-weight
$font-weight-lighter: lighter !default;
$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 900 !default;

//tooltip
$tooltip-arrow-width: 0;
$tooltip-arrow-height: 0;
$tooltip-bg: #ffffff;
$tooltip-color: $gray-800;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.5rem;
$tooltip-font-size: 0.875rem;
$tooltip-max-width: 180px;
$tooltip-border-radius: 4px;
