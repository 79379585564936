.form-control {
  border-color: $gray-300;
  color: $gray-800;
  border-radius: 4px;
  min-height: 33px;

  &-sm {
    min-height: 28px;
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
    border-color: $primary;
    color: $gray-800;
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $gray-700;

    &:hover {
      border-color: $gray-300;
    }
  }

  &.error {
    border-color: $danger;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: $gray-400;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer */
    color: $gray-400;
  }

  &::placeholder {
    color: $gray-400;
  }
}

input {
  &::-webkit-input-placeholder {
    /* Edge */
    color: $gray-400;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer */
    color: $gray-400;
  }

  &::placeholder {
    color: $gray-400;
  }
}
.rdw-editor {
  &-toolbar {
    border: 1px solid $gray-300 !important;
    margin-bottom: 0px !important;
  }
  &-main {
    border: 1px solid $gray-300;
    border-top: none;
    padding: 8px;
    min-height: 120px;
    .DraftEditor-root {
      z-index: 0;
    }
  }
}
.rdw-emoji-modal {
  left: auto !important;
  right: 0;
}
.rdw-link-modal {
  top: 20px !important;
  right: -135px;
  height: 230px !important;
}
.rdw-link-decorator-icon {
  left: 102% !important;
}
.rdw-link-decorator-wrapper {
  a {
    pointer-events: none;
    cursor: default;
  }
}
.wysiwyg-mention {
  pointer-events: none;
  cursor: default;
}
.rdw-suggestion-option {
  padding: 20px 1px !important;
}
.upload-file {
  border: 1px dashed $gray-300;
  p {
    max-width: 159px;
    margin: 0 auto;
    span {
      color: $primary;
    }
  }
}
