.profile {
  @extend .fade-in;

  label {
    margin: 0;
  }

  ul {
    padding: 0;
    list-style: none;
  }

  .user--image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    // border: 1px solid $primary-light-2;
    background: $primary-light-1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    img {
      max-width: 100%;
    }

    .text {
      text-transform: uppercase;
      font-size: 3rem;
      color: $white;
      // background: $primary;
      // padding: 0.56em 0;
    }
  }

  .timeline {
    &--item {
      padding: 0 0 15px 30px;
      position: relative;

      &:before {
        content: '';
        width: 10px;
        height: 10px;
        background: $gray-300;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 10px;
      }

      &:after {
        content: '';
        width: 1px;
        height: 100%;
        background: $gray-300;
        border-radius: 50%;
        position: absolute;
        top: 6px;
        left: 14px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  .timeline__tree-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .timeline__tree {
      margin-bottom: 20px;
      &--item {
        padding: 0 0 0 14px;
        position: relative;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          background: $gray-500;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          left: 0px;
        }
      }

      &--node {
        display: flex;
        align-items: center;

        &:after {
          content: '';
          width: 1px;
          height: calc(100% - 35px);

          background: $gray-300;
          border-radius: 50%;
          position: absolute;
          top: 20px;
          left: 42px;
        }
      }

      .inner-node-wrapper {
        display: flex;
        flex-direction: column;
        gap: 48px;
      }

      &--inner-node {
        position: relative;
        padding: 24px 0 0 28px;

        .project--header {
          display: flex;
          align-items: center;

          &::before {
            content: '';
            display: inline-block;
            width: 36px;
            height: 6px;

            margin-right: 12px;
            margin-bottom: 3.5px;

            border: 1.5px solid #dbdbdf;
            border-bottom: transparent;
            border-right: transparent;
            transform: scaleY(-1);
            border-start-start-radius: 25px;
          }
        }

        .project--detail {
          position: absolute;
          left: calc(48px + 28px);

          display: flex;
          flex-direction: column;

          &-list {
            display: flex;
            align-items: center;
            padding-top: 4px;
          }
        }

        & div .project--detail:not(:last-child) {
          position: absolute;
          display: flex;
          align-items: center;
          padding-top: 4px;
          left: calc(48px + 28px);

          margin-bottom: 8px;
        }
      }
    }
  }

  .badges {
    margin: 0 5px 10px 0;
    display: inline-block;
  }
}

.additional--block {
  padding: 12px 15px;
  border: 1px solid $gray-300;
  border-radius: 4px;
  margin-bottom: 15px;
}

.dot-common-style {
  margin-bottom: 2px;
  border-radius: 50%;
  display: inline-block;
}

.dot {
  height: 3px;
  width: 3px;
  background-color: $text-muted;

  @extend .dot-common-style;
}

.dot-sm {
  height: 6px;
  width: 6px;
  background-color: $gray-500;

  @extend .dot-common-style;
}

.dot-xs {
  height: 4px;
  width: 4px;
  background-color: $gray-500;
  margin: 0 4px;
  @extend .dot-common-style;
}
