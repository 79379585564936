.pagination {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  border-radius: 0px;
  color: $gray-800;
  margin: 0;

  .page-item {
    .page-link {
      color: $gray-500;
      padding: 0.2rem 0.5rem;

      &:hover {
        background-color: $bg-color;
        color: $gray-800;
        text-decoration: none;
      }
      &:focus {
        box-shadow: none;
        border-color: $primary;
      }
    }
    &.active {
      .page-link {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        &:hover {
          background-color: $primary-dark-1;
          border-color: $primary-dark-1;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }
  .form-control {
    width: 55px;
    max-height: 23px;
    min-height: 23px;
    border-radius: 0px;
    padding: 2px 4px;
    line-height: $pagination-line-height;
    font-size: 12px;
  }
  .page-arrow {
    .page-link {
      color: $primary;
    }
    &.disabled {
      .page-link {
        color: $gray-500;
      }
      cursor: not-allowed;
    }
  }

  &__container {
    position: sticky;
    bottom: 0;
    padding: 5px 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 2;
    font-size: 12px;
  }
}
