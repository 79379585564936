.css-b62m3t-container {
  position: relative;
}

.css-14el2xx-placeholder {
  white-space: nowrap;
  overflow: hidden;
  color: $gray-300 !important;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 0 4px 0 0 !important;
}

.css-1s2u09g-control {
  border-color: $gray-300 !important;
  box-shadow: none !important;
  min-height: 34px !important;

  &:hover {
    border-color: $primary !important;
  }

  .border-warning & {
    border-color: $warning !important;
  }
}

.css-1pahdxg-control {
  min-height: 34px !important;
  border-color: $primary !important;
  box-shadow: none !important;
  &:hover {
    border-color: $primary !important;
  }
}

.css-14dclt2-Input {
  margin: 0 !important;
}

[class*='-singleValue'] {
  padding: 0 6px;
  border-radius: 2px;
  font-size: 13px;
  margin: 0;
}

[class*='-MenuList'] {
  max-height: 285px !important;
  @extend .scrollbar;
}

[class*='-option'] {
  word-break: break-word;
  overflow: hidden;
  padding-right: 15px !important;

  label {
    width: calc(100% - 17px);
    margin-bottom: 0;
    vertical-align: top;
  }
}

.css-9gakcf-option {
  background-color: #cde1ff !important;
  color: $gray-900 !important;
}

.css-26l3qy-menu,
.css-1w9j89e-menu {
  min-width: 140px;
  z-index: 4 !important;
}

.rdw-dropdownoption-default {
  padding: 1px 5px !important;
}

.rdw-dropdown-optionwrapper {
  width: 100% !important;
}

.custom--selectall {
  padding: 8px 12px;
  label {
    margin: 0;
  }
}

.z-index-4 {
  z-index: 4;
}

.update-user-info-tags {
  .css-1rhbuit-multiValue {
    background: $accent-400;
    color: $white;
    border-radius: 18px;
    margin-right: 4px;
    padding: 1px 8px;

    .css-12jo7m5 {
      color: $white;
    }

    .css-xb97g8 {
      color: $white;
      font-weight: $font-weight-light;
      background-color: transparent;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }
  .list2Show {
    .css-1rhbuit-multiValue {
      &:nth-child(n + 3) {
        display: none;
      }

      &:nth-last-child(-n + 2) {
        display: flex;
      }
    }
  }
}

.tag-select {
  .css-1rhbuit-multiValue {
    background: none;
    padding: 0;
  }
}
