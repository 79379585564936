table {
  thead {
    tr th {
      font-weight: 600;
      border: 0;
      position: sticky;
      top: 0;
      background-color: #ffffff;
      text-transform: uppercase;
      z-index: 2;

      .sort-data {
        margin-left: 5px;
        cursor: pointer;
        &:hover {
          color: $primary;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        position: relative;
      }
      &.border-0 {
        td {
          border: 0;
        }
      }
      &:hover {
        .tbh-block {
          visibility: visible;
        }
      }
    }
  }

  .no-border {
    border: 0 !important;
  }

  .pdf-download {
    width: 104px;
    display: flex;
    align-items: center;

    .text {
      @include text-ellipsis(20px);
    }

    .download {
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: $primary;
        text-decoration: underline;
      }
    }
  }

  .td {
    &-lx {
      min-width: 325px;
      position: relative;
    }

    &-lg {
      min-width: 270px;
      position: relative;
    }

    &-md {
      min-width: 200px;
      position: relative;
    }

    &-sm {
      // min-width: 170px;
      // min-height: 30px;
      position: relative;
    }

    &-xs {
      min-width: 130px;
      width: 130px;
      position: relative;
    }

    &-xl {
      min-width: 80px;
      width: 80px;
      position: relative;
    }

    &__sticky--rtl {
      position: sticky;
      background-color: white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      right: 0;
    }
    &-spe {
      min-width: 300px;
      position: relative;
      min-height: 64px;
      max-height: 64px;
    }
    &-spe2 {
      min-width: 200px;
      position: relative;
      min-height: 64px;
      max-height: 64px;
    }
  }

  &.sticky--ltr {
    border: none;
    th,
    td {
      border: none;
      box-sizing: border-box;
    }
    th {
      box-shadow: inset -1px 0 0 0 $gray-300, inset 0 -1px 0 0 $gray-300, inset 0 1px 0 0 $gray-300;
      padding: 0.5rem 0.75rem;
      &:first-child {
        left: 0;
        z-index: 3;
        box-shadow: inset 0 0 0 1px $gray-300;
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
    }
    tr {
      td {
        box-shadow: inset -1px 0 0 0 $gray-300, inset 0 -1px 0 0 $gray-300;
        &.td-sm {
          min-height: 30px;
          border: none;
          position: relative;
        }
        &:first-child {
          position: sticky;
          left: 0;
          z-index: 1;
          box-shadow: inset -1px 0 0 0 $gray-300, inset 1px 0 0 0 $gray-300, inset 0 -1px 0 0 $gray-300;
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }

  &.sticky--rtr {
    td:last-child {
      position: sticky;
      background-color: inherit;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      left: 0;
      z-index: 1;
    }

    thead tr th:last-child {
      left: 0;
      z-index: 3;
    }
  }

  .dropdown,
  .dropleft {
    .dropdown-menu {
      max-height: 300px;
      @extend .scrollbar;
    }

    .dropdown-menu.overflow-visible {
      overflow: visible !important;
    }
    // &:hover .dropdown-menu {
    //   display: block;
    //   opacity: 1 !important;
    // }
  }

  .link {
    padding: 0;
    cursor: pointer;
    color: $primary;

    &:hover {
      text-decoration: underline;
      color: $primary-dark-1 !important;
    }
  }

  .btn-sm {
    padding: 2px 12px;
    min-width: auto;
  }

  &.box-border {
    border-spacing: 0 10px;
    border-collapse: separate;

    thead {
      tr th {
        border: 0;
      }
    }

    tbody tr td {
      border-style: solid;
      border-width: 1px 0 1px 0;
      border-color: $gray-300;
      vertical-align: middle;

      &:first-child {
        border-left-width: 1px;
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-right-width: 1px;
        border-radius: 0 4px 4px 0;
      }
    }
  }

  &.tb-hover {
    border-collapse: unset;
    th,
    td {
      border: 0;
    }

    .hidden-element {
      visibility: hidden;
      opacity: 0;
    }

    tr:hover {
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);

      .hidden-element {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .line_timeline {
    position: relative;
    border: 1px solid $gray-300;
    border-radius: 8px;
    padding: 12px 15px;
    margin-left: 30px;
    margin-top: 20px;
    max-width: 90%;

    &:after {
      content: '';
      width: 1px;
      height: 28px;
      background: $gray-300;
      border-radius: 50%;
      position: absolute;
      top: -20px;
      left: -20px;
    }

    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 6px;
      left: -20px;
      position: absolute;
      border: 1.5px solid $gray-300;
      border-bottom: transparent;
      border-right: transparent;
      transform: scaleY(-1);
      border-start-start-radius: 25px;
    }

    .item {
    }
  }

  .btn-expand-row {
    border: 1px solid $primary;
    min-width: auto;
    border-radius: 50px;
    padding: 1px 2px;
    width: 20px;
    height: 20px;
    font-size: 12px;
    margin-left: 15px;

    span {
      color: currentColor;
    }
  }

  .tbh-block {
    visibility: hidden;
  }

  &.bg-light {
    thead tr th {
      background-color: $bg-color;
    }
    tbody tr td {
      background-color: $white;
    }
  }

  .error {
    // position: absolute;

    &:after {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 12px 12px 0 0;
      border-color: $red transparent transparent transparent;
      transform: rotate(0deg);
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.copy {
  font-weight: 600;
  cursor: pointer;
}

.th {
  &-4 {
    min-height: calc(100vh - 400px);
  }
  &-5 {
    min-height: calc(100vh - 355px);
  }
  &-6 {
    min-height: calc(100vh - 332px);
  }
  &-8 {
    min-height: calc(100vh - 317px);
  }
  &-9 {
    min-height: calc(100vh - 244px);
  }
  &-10 {
    min-height: calc(100vh - 190px);
  }
  &-11 {
    min-height: calc(100vh - 188px);
  }
  &-12 {
    min-height: calc(100vh - 182px);
  }

  &__mh {
    &-4 {
      max-height: calc(100vh - 400px);
    }
    &-5 {
      max-height: calc(100vh - 355px);
    }
    &-6 {
      max-height: calc(100vh - 332px);
    }
    &-8 {
      max-height: calc(100vh - 317px);
    }
    &-9 {
      max-height: calc(100vh - 244px);
    }
    &-10 {
      max-height: calc(100vh - 190px);
    }
    &-11 {
      max-height: calc(100vh - 188px);
    }
    &-12 {
      max-height: calc(100vh - 182px);
    }
  }
}

.table-sm {
  td {
    padding: 0.4rem 0.4rem 0.4rem 0.8rem !important;
  }
  th,
  td {
    font-size: 0.875rem;
    padding-left: 0.8rem;

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
      // width: 75%;
    }
    .table-badges {
      display: flex;
      align-items: center;
      .badges,
      .link-truncate {
        max-width: 100%;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .badges {
      white-space: nowrap;
    }
    .num {
      visibility: visible;
    }

    .form-check-input {
      position: absolute;
      margin-left: 0;
      left: 0;
      visibility: hidden;
      cursor: pointer;
    }
  }
  tr {
    td {
      background-color: white;

      .opt-edit {
        display: inline-block;
        vertical-align: middle;

        > div > .btn {
          visibility: hidden;
        }
      }

      &:hover {
        background-color: #f9f8fc !important;

        .opt-edit {
          > div > .btn {
            visibility: visible;
          }
        }
      }
    }
    &:hover {
      td {
        background-color: #f1f1f1 !important;
        .num {
          // visibility: hidden;
        }

        .form-check-input {
          visibility: visible;
        }
      }
    }

    &.active {
      background-color: $gray-100 !important;
      td {
        .num {
          visibility: hidden;
        }

        .form-check-input {
          visibility: visible;
        }

        &:first-child,
        &:last-child {
          background-color: inherit !important;
        }
      }
    }
  }

  th {
    &.draggable {
      &:hover {
        background-color: #f1f1f1;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='3' r='1' fill='%2385858D'/%3E%3Ccircle cx='4' cy='9' r='1' fill='%2385858D'/%3E%3Ccircle cx='4' cy='6' r='1' fill='%2385858D'/%3E%3Ccircle cx='8' cy='9' r='1' fill='%2385858D'/%3E%3Ccircle cx='8' cy='6' r='1' fill='%2385858D'/%3E%3Ccircle cx='8' cy='3' r='1' fill='%2385858D'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: -1px 10px;
      }
    }
  }
}

.employee-list-table {
  .table-sm {
    td {
      padding: 8px 12px;
      // max-width: 100px;
    }
  }
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: $gray-600;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.parent {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  div,
  span,
  a,
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.ov-visible {
    overflow: visible;
    div,
    span,
    a,
    p {
      overflow: visible;
    }
  }
}
.fit-content {
  max-width: fit-content;
}
