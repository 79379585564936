.login {
  &__wrapper {
    height: 100vh;
    display: flex;

    background: #e5e5e5;

    .left,
    .right {
      width: 100%;
      padding: 16px 16px;
      height: 100%;
    }

    .right {
      background: $primary;
      color: $white;
      text-align: center;
    }

    .content {
      max-width: 560px;
      width: 100%;
      margin: auto;
      @include vertical-align;

      .divider {
        margin: 24px 0;
        color: $gray-800;
        position: relative;
        text-align: center;

        .h4 {
          padding-right: 5px;
          display: inline-block;
          background: $white;
          margin: 0;
          z-index: 1;
          position: relative;
        }

        &:after {
          position: absolute;
          content: '';
          height: 1px;
          background: $gray-300;
          left: 0;
          right: 0;
          top: 50%;
        }
      }
    }

    .password--rest {
      max-width: 450px;
      width: 100%;
      margin: auto;
      padding: 12px 15px;

      form {
        background: $white;
        border-radius: 8px;
      }
    }
  }

  &__form {
    max-width: 512px;
    width: 100%;
    margin: auto;

    .social--login {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      column-gap: 10px;

      .btn {
        min-width: 130px;
        text-transform: none;
        background: $white;
        flex: 1 auto;

        img {
          margin-right: 5px;
          vertical-align: bottom;
        }
      }
    }

    form {
      background: $white;
      border-radius: 8px;
    }
  }
}
