.custom-radio {
  position: relative;
  padding: 0;
  background: $white;

  .custom-control-label {
    border-radius: 4px;
    background: $white;
    border: 1px solid $gray-300;
    padding: 6px 16px 6px 28px;
    display: block;
    cursor: pointer;

    &:before {
      width: 15px;
      height: 15px;
      left: 8px;
      top: 9px;
    }
  }

  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        border: 1px solid $primary;

        &:after {
          // background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='4' fill='%236FB989' stroke='%236FB989' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background: $primary;
          width: 9px;
          height: 9px;
          border-radius: 10px;
          left: 11px;
          top: 12px;
        }

        &:before {
          background: transparent;
          border-color: $primary;
        }
      }
    }

    &:disabled,
    &[disabled] {
      ~ .custom-control-label {
        &:before {
          border: 1px solid $gray-400;
        }
      }
    }

    &:focus {
      ~ .custom-control-label {
        &:before {
          // box-shadow: none;
          outline: 2px solid $white;
          // border-radius: 0;
        }
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &.custom-inline {
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;

    &:last-child {
      margin: 0;
    }
  }

  &.border-0 {
    .custom-control-label {
      padding: 2px 5px 2px 25px;
      display: block;
      cursor: pointer;
      border: 0;

      &:before {
        width: 15px;
        height: 15px;
        left: 4px;
        top: 6px;
      }
    }

    .custom-control-input {
      border: 0;
      &:checked {
        & ~ .custom-control-label {
          border: 0;
          &:after {
            background: $primary;
            width: 9px;
            height: 9px;
            border-radius: 10px;
            left: 7px;
            top: 9px;
          }
        }
      }
    }
  }
}

.filter-radio-btn-container {
  display: flex;
  justify-content: space-between;
}

.d-block-2 {
  display: flex;
  justify-content: space-between;

  .custom-radio {
    width: 50%;

    .custom-control-label {
      width: 100%;
    }
  }
}
