.invoice-container {
  border-radius: 8px;
  border: 1px solid $gray-200;
  box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  min-height: calc(100vh - 69px - 62px);

  .invoice-table {
    .collapse.show,
    .collapsing {
      display: table-row;
    }

    tr {
      > *:first-child {
        max-width: 65px;
        width: 65px;
      }
    }

    tbody tr {
      cursor: pointer;

      .icon {
        display: none;
        font-weight: 700 !important;
      }

      &:hover {
        // background-color: lighten($primary, 47%);
        .num {
          display: none;
        }
        .icon {
          display: block;
        }
      }
    }

    .int-table {
      border-radius: 8px;
      overflow: hidden;

      table {
        thead tr th {
          background: $gray-300;
        }
        tbody tr td {
          &.bg-blue-light {
            background-color: lighten($primary, 47%);
          }
        }

        tr {
          > *:first-child {
            width: auto;
          }
        }
      }
    }
  }
}
