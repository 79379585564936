.upload {
  &--container {
    background-color: $bg-color;
    padding: 1px 25px;
    border-radius: 8px;

    .uploader {
      text-align: center;
      position: relative;
      padding: 25px 25px;
      cursor: default;

      #upload-file {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
      }

      label {
        cursor: pointer;
      }
    }

    .content {
      background-color: $white;
      border-radius: 12px;
      border: 1px dashed $white;
      padding: 25px 25px;
      margin: 25px 0;
    }

    &:first-child {
      padding: 0;
    }
  }
}
