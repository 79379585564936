.top--nav {
  padding: 8px 0;
  background: $primary-dark-1;
  display: flex;
  align-items: center;
  min-height: 62px;

  .nav {
    &--left {
      padding: 0 15px;
      .logo {
        &--sm {
          display: none;
        }
      }

      .logo-content {
        color: $white;
        margin-left: 12px;
        .logo {
          font-size: $h4-font-size;
          font-weight: 600;
          line-height: 1;
        }
      }

      .nav--breadcrumb {
        margin-left: 16px;
        padding-left: 16px;
        border-left: 1px solid #fff;
        color: $white;
        display: flex;
        align-items: center;

        > a,
        .link {
          cursor: pointer;
          color: $primary-light-2;

          &:hover {
            text-decoration: underline;
            color: $white;
          }
        }

        .dropdown-toggle {
          padding: 4px 16px;
          background-color: $white;
          border-radius: 20px;
        }
        .dropdown-menu {
          max-height: 230px;
          @extend .scrollbar;
        }

        .btn {
          line-height: 1;
        }
      }
    }
    &--right {
      padding: 0 15px;
      margin-left: auto;

      .user {
        display: flex;
        align-items: center;
        color: $white;
        width: 100%;
        position: relative;
        max-width: 260px;
        padding: 4px 8px 4px 5px;
        border-radius: 40px;
        background: $primary;
        cursor: pointer;

        .image--block {
          position: relative;
          width: 38px;
          height: 38px;
          border: 1px solid $primary-light-2;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 8px;
          background: $primary-light-1;
          text-align: center;
          color: $primary;

          .text {
            margin: 0;
            line-height: 36px;
            font-size: 16px;
            font-weight: 500;
            color: $white;
            text-transform: uppercase;
            @include transition-css(all, ease-in-out, 300ms);
            display: block;
          }

          img {
            max-width: 100%;
          }
        }

        &__info {
          width: calc(100% - 63px);
          overflow: hidden;
          @include transition-css(all, ease-in-out, 300ms);
        }

        .ft-angle-down {
          margin-top: 2px;
        }

        &:hover {
          text-decoration: none;
          background: $primary;
        }
      }

      .dropdown-menu {
        padding: 24px 20px;
        border-radius: 0px 0px 8px 8px;
        box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
        border: 0;

        .user {
          border-radius: 0;
          background: $white;

          color: $gray-900;

          &:hover {
            text-decoration: none;
            background: $primary-light-2;
            color: $white;
          }
        }
      }
    }
  }
}

.nav--grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 30px;

  .nav__item {
    border-top: 4px solid $primary;
    border-radius: 4px;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
    padding: 16px 16px;
    max-width: 100%;
    color: $primary-dark-1;

    span {
      display: block;

      &.title {
        font-size: $h4-font-size;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }
}

// task dashboard nav
.td_nav {
  margin: 0;
  padding: 0;
  list-style: none;

  .item {
    padding: 12px 16px;
    margin-top: 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    align-items: center;

    .count {
      padding: 4px 10px;
      border-radius: 4px;
      border: 1px solid currentColor;
      position: relative;

      .new {
        width: 10px;
        height: 10px;
        position: absolute;
        border: 2px solid white;
        background-color: $red;
        border-radius: 10px;
        top: -5px;
        right: -5px;
      }
    }

    &:hover {
      background-color: $gray-200;
    }

    &.active {
      color: $primary;
      background-color: $gray-200;
    }

    &.disabled {
      cursor: default;
      opacity: 0.6;
      background-color: transparent;
    }
  }
}
