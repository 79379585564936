@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin hv-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin box-shadow($left, $blur, $size, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $left $blur $size $color;
    -moz-box-shadow: inset $left $blur $size $color;
    box-shadow: inset $left $blur $size $color;
  } @else {
    -webkit-box-shadow: $left $blur $size $color;
    -moz-box-shadow: $left $blur $size $color;
    box-shadow: $left $blur $size $color;
  }
}

@mixin text-ellipsis($widthRemove) {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - $widthRemove);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Add new mizins.scss
@mixin translate($left, $top) {
  -webkit-transform: translate($left, $top);
  -moz-transform: translate($left, $top);
  -ms-transform: translate($left, $top);
  -o-transform: translate($left, $top);
  transform: translate($left, $top);
}

@mixin transition-css($transition-property, $transition-duration, $timing-function) {
  -webkit-transition: $transition-property $transition-duration $timing-function;
  -moz-transition: $transition-property $transition-duration $timing-function;
  -o-transition: $transition-property $transition-duration $timing-function;
  transition: $transition-property $transition-duration $timing-function;
}

@mixin grayscale($gray) {
  -webkit-filter: grayscale($gray);
  -moz-filter: grayscale($gray);
  filter: grayscale($gray);
}

@mixin rotate($deg) {
  transform: rotate($deg);
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -ms-transform: rotate($deg);
}

@mixin transform-scale($scaleX, $scaleY) {
  transform: scale($scaleX, $scaleY);
  -webkit-transform: scale($scaleX, $scaleY);
  -moz-transform: scale($scaleX, $scaleY);
  -ms-transform: scale($scaleX, $scaleY);
}

@mixin font-size($fontSize, $lineHeight) {
  font-size: $fontSize;
  line-height: $lineHeight;
}

// fiter type and amount
@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type + unquote('(#{$filter-amount})');
  -moz-filter: $filter-type + unquote('(#{$filter-amount})');
  -ms-filter: $filter-type + unquote('(#{$filter-amount})');
  -o-filter: $filter-type + unquote('(#{$filter-amount})');
  filter: $filter-type + unquote('(#{$filter-amount})');
}

// linear-gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin border-radius($radius) {
  border-radius: $radius;
}

// color defin function
@mixin config-text-colors($prefix, $colors, $property) {
  @each $i in $colors {
    .#{$prefix}#{nth($i, 1)} {
      #{$property}: nth($i, 2) !important;
    }
  }
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin only-for-mobile {
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    @content;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    @media (orientation: landscape) {
      @content;
    }
  }
}

@mixin only-for-tablet {
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin only-for-tablet-landscape {
  // Small devices (landscape ipad, less than 768px)
  @media (max-width: 1024.98px) {
    @media (orientation: landscape) {
      @content;
    }
  }
}

@mixin only-for-desktop {
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1359.98px) {
    @content;
  }
}
