.login {
  &__wrapper {
    height: auto;
    display: flex;
    min-height: 100vh;

    .left {
      width: 100%;
      min-height: 100vh;

      .content {
        top: 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }

    .right {
      display: none;
    }
  }
}
