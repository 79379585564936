.dropdown {
  &-toggle {
    &:focus {
      box-shadow: none !important;
    }

    &.p-drp {
      padding: 2px 12px;
      border-radius: 16px;
      background-color: lighten($primary, 40%);
      border: 1px solid $primary;
      cursor: pointer;
      font-size: $font-size-sm;
    }
  }

  &-menu {
    padding: 0;
    border-radius: 2px;
    overflow: visible;
    min-width: 80px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    .dropdown-item {
      padding: 8px 12px;
      border-bottom: 1px solid $gray-200;
      cursor: pointer;
      // min-width: 150px;

      &:hover,
      &.active {
        background-color: $bg-color;
        color: inherit;
      }

      &:last-child {
        border: 0;
      }
    }

    &.w-xl {
      width: 580px;
      padding: 8px 15px;
    }

    &.w-lg {
      width: 400px;
      padding: 8px 15px;
    }

    &.w-md {
      width: 300px;
      padding: 8px 15px;
    }

    &.w-sm {
      width: 164px;
      padding: 8px 15px;
    }
  }

  &.icon {
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        padding: 6px 8px;
        font-size: $font-size-sm;
      }
    }
  }
  &-form {
    overflow: visible;
    display: flex;
    padding: 0.5rem 1rem;
    .dropdown-item {
      padding: 0;
      border: 0;
      &:hover {
        background-color: transparent;
      }
      button {
        padding: 0 0.5rem;
      }
    }
  }
}
