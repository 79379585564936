.DayPickerInput {
  width: 100%;
  .right ~ .DayPickerInput-OverlayWrapper .DayPickerInput-Overlay {
    left: auto;
    right: 0;
  }
}

.DayPicker {
  &-Month {
    border-collapse: separate !important;
    border-spacing: 0 2px !important;
  }

  &-Day {
    &--selected {
      background-color: $bg-color !important;
      color: $gray-900 !important;
      border-radius: 0 !important;

      &.DayPicker-Day--end,
      &.DayPicker-Day--start {
        background-color: $primary !important;
        color: $white !important;
      }

      &.DayPicker-Day--end {
        border-radius: 0 4px 4px 0 !important;
      }

      &.DayPicker-Day--start {
        border-radius: 4px 0 0 4px !important;
      }
    }
  }
}
