.nav {
  border-bottom: none;
  &-tabs {
    .nav-item {
      margin-right: 8px;
    }
    .nav-link {
      color: $gray-800;
      border: none;
      font-size: $font-size-lg;
      line-height: 24px;
      padding: 4px;
      font-weight: 500;
      display: inline-block;
      padding: 0.3rem 1rem;
      border-bottom: 2px solid transparent;
      display: block;
      &:hover {
        color: $primary;
        border-color: transparent;
      }
      &.active {
        border-bottom: 2px solid $primary;
        color: $primary;
        background-color: #f0edf7;
        border-radius: 8px 8px 0 0;
      }

      &.sm {
        font-size: $font-size-base;
        padding: 8px 6px;
        display: flex;
      }
    }

    &.flex-md-column {
      .nav-item {
        margin: 0 0 3rem 0;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  &-block {
    display: inline-flex;
    .nav-item {
      margin-right: 0.5rem;

      .nav-link {
        border: 1px solid currentColor;
        padding: 0.56rem 1.4rem;
        border-radius: 100px;
        @extend .h5;
        display: block;
        color: $gray-500;
        margin: 0;
        font-weight: 600;

        &:hover {
          color: $primary;
        }

        &.active {
          color: $primary;
          background-color: lighten($primary, 45%);
        }
      }
    }
  }

  &-box {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 10px;

    .nav-item {
      margin-right: 8px;
    }
    .nav-link {
      font-size: $font-size-base;
      background-color: #f0edf7;
      display: block;
      border-radius: 8px 8px;
      color: $primary;
      position: relative;
      &:hover {
        color: $primary;
        border-color: transparent;
      }
      .custom-checkbox .custom-control-label:before,
      .custom-checkbox .custom-control-label:after {
        background-color: $white;
      }

      .icon {
        color: $gray-300;
        display: none;
        color: $gray-800;
        width: 16.6px;
        height: 8.8px;
        position: absolute;
        bottom: -11.5px;
        background-color: $white;
        left: 45%;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: calc(50% - 0.5px);
          width: 1px;
          height: 12px;
          border-radius: 9999px;
          background-color: $gray-500;
          transform-origin: 50% 0.5px;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      &.active {
        border-bottom: none;
        color: $white;
        background-color: $primary;
        color: $white;
        border-radius: 8px 8px;

        .icon {
          display: block;
        }
      }
    }
  }
}
