.input-group {
  border: 1px solid $gray-300;
  border-radius: 4px;
  // overflow: hidden;

  &-prepend {
    > * {
      border-radius: 4px 0 0 4px;
      background-color: $white;
      border: transparent;
      padding-right: 0;
    }
  }

  &-append {
    > * {
      border-radius: 0 4px 4px 0;
      background-color: $white;
      border: transparent;
      padding-left: 0;
    }
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active,
  &.active {
    border-color: $primary;
    box-shadow: none;
  }

  > .form-control {
    border: 0;
  }
}
