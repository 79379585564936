.multi--select {
  .css-1s2u09g-control {
    min-height: 120px;
    align-items: flex-start;
    padding: 8px;
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }

  .css-1pahdxg-control {
    min-height: 120px;
    align-items: flex-start;
    padding: 8px;

    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }

  .css-1rhbuit-multiValue {
    background: $secondary-light-2;
    color: $secondary-dark-2;
    border-radius: 18px;
    margin-right: 4px;
    padding: 1px 8px;

    .css-12jo7m5 {
      color: $secondary-dark-2;
    }

    .css-xb97g8 {
      color: $secondary-dark-1;
      font-weight: $font-weight-light;
    }
  }
}
.question {
  &__title {
    min-height: 65px;
  }
  &-option {
    background: $white;
    display: flex;
    align-items: flex-start;
    padding: 4px 0px 4px 16px;

    input {
      margin-top: 8px;
    }
  }
}
.upload-image {
  .question {
    &__icon {
      min-width: 32px;
      min-height: 32px;
      margin-top: 22px;
      cursor: pointer;
    }
    &__image {
      min-width: 48px;
      max-width: 48px;
      min-height: 48px;
      max-height: 48px;
      margin-top: 25px;
      cursor: pointer;
    }
    &__cross {
      position: absolute;
      z-index: 3;
      bottom: 0;
      right: 0;
      margin-bottom: 39px;
      margin-right: -17px;
    }
  }
  .answer {
    &__icon {
      min-width: 32px;
      min-height: 32px;

      cursor: pointer;
    }
    &__image {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      margin-top: -6px;
      cursor: pointer;
    }
    &__cross {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      margin-top: -18px;
      margin-left: 9px;
    }
  }
  .ft-circle-cross {
    background-color: $white;
    color: $gray-800;
    border-radius: 50%;
    font-size: 20px;
  }

  input[type='file'] {
    position: absolute;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    z-index: 0;
  }
}
.sort {
  display: flex;
  flex-direction: column;
  &-button {
    font-size: 1px;
    cursor: pointer;
    color: $primary-light-1;
    &:hover {
      color: $primary-dark-1;
    }
  }
  .active {
    color: $primary-dark-1;
  }
}
