.timeline {
  &-h {
    padding-left: 30px;
    margin-top: 1rem;
    position: relative;

    .line {
      position: relative;

      &:after {
        content: '';
        width: 2px;
        height: 100%;
        background: $gray-800;
        position: absolute;
        left: -22px;
        top: 0px;
      }

      &-dot {
        position: relative;

        &:after {
          content: '';
          width: 8px;
          height: 8px;
          background: $primary;
          position: absolute;
          border-radius: 50%;
          left: -25px;
          top: 8px;
        }
      }

      .sub-lts {
        border: 1px solid $border-color;
        border-radius: 4px;
        position: relative;
        z-index: 1;
        padding: 12px 16px;
        margin: 12px 0 12px 24px;
        backface-visibility: hidden;
        font-size: $small-font-size;
        ul {
          padding-left: 12px;

          &:last-child {
            margin: 0;
          }
        }

        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 36px;
          border: 1px solid $border-color;
          border-width: 0 0 1px 1px;
          position: absolute;
          left: -16px;
          top: -15px;
          z-index: 0;
        }
      }
    }
  }
}
