.userNote-editor {
  .wrapper-class {
    display: flex;
    flex-direction: column;

    .editor-class {
      order: 1;
      border: 1px solid #dbdbdf;
      border-bottom: 0;
    }

    .toolbarClass {
      order: 2;
      align-items: center;
      .form-group {
        margin: 0;
      }

      .rdw-option-wrapper {
        border: 0;
      }
      .rdw-block-wrapper,
      .rdw-fontsize-wrapper,
      .rdw-emoji-wrapper,
      .rdw-history-wrapper,
      .rdw-remove-wrapper,
      .rdw-link-wrapper,
      .rdw-list-wrapper {
        display: none;
      }
    }
  }
}
.rdw-editor-main {
  overflow: visible !important;
}
.wrapper-class {
  [class*='-option'] {
    overflow: visible !important;
  }
  .rdw-mention-link {
    color: #164fb1;
    cursor: pointer;
  }
  .rdw-suggestion-dropdown {
    // position: fixed;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);

    .rdw-suggestion-option {
      display: block;
      padding: 12px 30px 12px 12px !important;
      cursor: pointer;
      min-width: max-content;

      &:hover {
        background-color: $bg-color;
      }
    }
  }

  &.border-warning {
    .rdw-editor-toolbar,
    .rdw-editor-main {
      border-color: $warning !important;
    }
  }
}
