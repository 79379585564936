.analytics-cards {
  flex-wrap: wrap;
  min-width: 18rem;

  .card.has-details {
    min-width: 26.5rem;
  }

  .card {
    flex: 1 0 22%;
    min-width: 17.8rem;

    .card-head {
      margin-bottom: 1rem;
    }

    .card-icon {
      font-size: 1.1rem;
    }

    .ft-arrow-up-right {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }
}

.section-projects {
  .project-table {
    border-radius: 8px;
    // border: ;
    &--header {
      background-color: $bg-color;
      @extend .fw-bold;
    }
    &--header:first-child {
      border-radius: 8px 8px 0 0;
    }
    &--header:not(:first-child) {
      margin-top: 1.15rem;
    }

    &--body {
      .card {
        min-width: 17rem;
      }

      h4.project-type {
        font-size: 1.25rem;
      }
    }
  }
}

@include only-for-mobile() {
  .analytics-cards {
    flex-direction: column;
  }
}
