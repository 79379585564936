@import 'react-select';
@import 'react-slider';
@import 'daypicker';
@import 'react-slick';
@import 'react-draft-editor';

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
}
