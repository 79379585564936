.empty {
  &-case {
    display: block;
    max-width: 600px;
    width: 100%;
    margin: 100px auto 15px;
    text-align: center;

    img {
      margin-bottom: 15px;
    }

    &-table {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
