.section-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  .form-wrapper {
    display: flex;
    gap: 1.2rem;
  }
  @include only-for-mobile {
    flex-direction: column;
    padding-left: 0;
  }
}

.section-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  gap: 2rem;

  .card {
    padding: 1rem;

    &-title {
      @extend .fw-bold;
    }

    &-content {
      font-weight: 700;
    }

    &.card-approved .card-content {
      color: $success;
    }

    &.card-pending .card-content {
      color: $info;
    }

    &.card-remaining .card-content {
      color: $gray-500;
    }

    &.card-overtime .card-content {
      color: $danger;
    }
  }
}

.section-updates {
  .updates {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .update {
      display: flex;
      gap: 1rem;

      &-details {
        display: flex;
        flex-direction: column;
        gap: prem(6px);
      }

      i {
        border-radius: 50%;
        padding: prem(6);
      }

      .icon-tick {
        i {
          border: 1px solid $success;
        }
      }

      .icon-close {
        i {
          border: 1px solid $danger;
        }
      }

      .icon-comment {
        i {
          border: 1px solid $primary;
        }
      }

      &-title {
        display: flex;
        align-items: center;

        h5 {
          @extend .fw-bold;
          margin-bottom: 0;
        }

        i {
          //icon
          font-size: 6px;
          padding: 0 prem(8);
          color: $gray-400;
        }
      }

      .timestamp {
        font-size: $small-font-size;
        @extend .fw-light;
      }
    }
  }
}

h4 {
  @extend .fw-bold;
}

.section-sqaudlist {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .card-squadlist {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@include only-for-mobile {
  .cards-row {
    row-gap: 2rem;
  }
}

.icon-circle {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(128, 128, 128, 0.523);

  @include hv-center;
  flex-shrink: 0;
}
