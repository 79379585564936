@include only-for-tablet-landscape {
}

@include only-for-tablet() {
  aside.light {
    max-width: 64px;
    min-width: 10px;

    .sidebar {
      &--block {
        .toggle-md {
          span {
            @extend .ft-rotate-90;
          }
        }
      }
      &__content {
        padding: 16px 8px;
      }
    }

    .nav {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      &--item {
        .text {
          margin-left: 0px;
          max-width: 1px;
          overflow: hidden;
        }
      }

      &__dropdown {
        background: $white;
        .nav--item:first-child {
          display: none;
        }
      }
    }

    &.sidebar__sm {
      max-width: 64px;

      .sidebar {
        &--block {
          position: absolute;
          background: #fff;
          z-index: 9;
          border-right: 1px solid #0000001f;
          .toggle-md {
            @extend .ft-rotate-180;
          }
        }
      }

      .nav__dropdown {
        border-radius: 4px;
        overflow: hidden;
        max-height: 61px;

        @include transition-css(all, 300ms, ease-in-out);

        .nav--item:first-child:hover {
          font-weight: $font-weight-normal;
          color: inherit;
          background-color: $bg-color;
        }

        &.show {
          background-color: $bg-color;
          max-height: 400px;
        }
      }

      .nav--item {
        .text {
          margin-left: 10px;
          width: calc(100% - 31px);
          max-width: 100%;
          @include text-truncate;
          @include transition-css(all, ease-in-out, 300ms);
        }
      }
    }
  }
}
