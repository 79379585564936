$font-weights: (
  'lighter': 200,
  'light': 300,
  'normal': 400,
  'medium': 500,
  'bold': 600,
  'bolder': 700,
);

@each $font-weight-name, $font-weight-value in $font-weights {
  .fw-#{$font-weight-name} {
    font-weight: $font-weight-value;
  }
}

// $font-weights: (200, 300, 400, 500, 600, 700, 800);

// @each $font-weight in $font-weights {
//   .fw-#{$font-weight} {
//     font-weight: $font-weight;
//   }
// }
