.badges {
  padding: 1px 10px;
  font-size: $font-size-sm;
  display: inline-block;
  margin: 2px 3px;

  &__rounder {
    border-radius: 18px;
    color: $white;

    &-orange {
      background: $orange;

      &--text {
        color: $gray-800;
        background: lighten($orange, 25%);
      }

      &--tag {
        color: $orange;
        background: lighten($orange, 25%);
      }
    }

    &-blue {
      background: $blue;

      &--text {
        color: $gray-800;
        background: lighten($blue, 30%);
      }
      &--tag {
        color: $blue;
        background: lighten($blue, 30%);
      }
    }

    &-purple {
      background: $purple;

      &--text {
        color: $gray-800;
        background: lighten($purple, 44%);
      }
      &--tag {
        color: $purple;
        background: lighten($purple, 44%);
      }
    }

    &-green {
      background: $green;

      &--text {
        color: $gray-800;
        background: lighten($green, 32%);
      }

      &--tag {
        color: $green;
        background: lighten($green, 32%);
      }
    }

    &-red {
      background: $red;

      &--text {
        color: $gray-800;
        background: lighten($red, 30%);
      }

      &--tag {
        color: $red;
        background: lighten($red, 30%);
      }
    }

    &-magenta {
      background: $pink;

      &--text {
        color: $gray-800;
        background: lighten($pink, 23%);
      }

      &--tag {
        color: $pink;
        background: lighten($pink, 23%);
      }
    }

    &-yellow {
      background: $yellow;

      &--text {
        color: $gray-800;
        background: lighten($yellow, 30%);
      }

      &--tag {
        color: $yellow;
        background: lighten($yellow, 30%);
      }
    }

    &-gray {
      background: $gray-400;
      color: $gray-800;

      &--text {
        color: $gray-800;
        background: $gray-100;
      }

      &--tag {
        color: $gray-800;
        background: $gray-100;
      }
    }
  }

  &__rectangle {
    border-radius: 4px;

    &-purple {
      background: $bg-color;
      color: $primary;
      &--dark {
        background: $purple;
        color: $white;
      }
      &--tag {
        color: $purple;
        background: lighten($purple, 44%);
      }
    }

    &-orange {
      background: $info-light;
      color: $info;
      &--dark {
        background: $orange;
        color: $white;
      }
      &--tag {
        color: $orange;
        background: lighten($orange, 25%);
      }
    }

    &-yellow {
      background: $warning-light;
      color: $warning;
      &--dark {
        background: $warning;
        color: $white;
      }
      &--tag {
        color: $yellow;
        background: lighten($yellow, 30%);
      }
    }

    &-red {
      background: $danger-light;
      color: $danger;
      &--dark {
        background: $danger;
        color: $white;
      }
      &--tag {
        color: $red;
        background: lighten($red, 30%);
      }
    }

    &-green {
      background: $success-light;
      color: $success;
      &--dark {
        background: $success;
        color: $white;
      }
      &--tag {
        color: $green;
        background: lighten($green, 32%);
      }
    }

    &-blue {
      background: $blue;
      color: $primary;
      &--dark {
        background: $blue;
        color: $white;
      }
      &--tag {
        color: $blue;
        background: lighten($blue, 30%);
      }
    }

    &-magenta {
      color: $pink;
      background: lighten($pink, 23%);
      &--dark {
        color: $gray-800;
        background: $pink;
      }
      &--tag {
        color: $pink;
        background: lighten($pink, 23%);
      }
    }

    &-gray {
      background: $bg-color;
      color: $gray-900;
      &--tag {
        color: $gray-800;
        background: $gray-100;
      }
    }

    &-primary {
      background: $primary;
      color: $white;
    }
  }

  &--secondary {
    background: $secondary-light-2;
    color: $secondary-dark-2;
  }
}

.badges {
  padding: 1px 8px;
  font-size: $font-size-sm;
  display: inline-block;
  margin: 2px 3px;

  &__rounder {
    border-radius: 18px;
  }

  &__rectangle {
    border-radius: 4px;
  }

  &-primary {
    background: $primary;
    color: $white;
  }

  &-purple {
    background: $purple;

    &--light {
      color: $gray-800;
      background: lighten($purple, 44%);
    }
    &--tag {
      color: $purple;
      background: lighten($purple, 44%);
    }
  }

  &-orange {
    background: $orange;

    &--light {
      color: $gray-800;
      background: $info-light;
    }

    &--tag {
      color: $orange;
      background: $info-light;
    }
  }

  &-yellow {
    background: $yellow;

    &--light {
      color: $gray-800;
      background: $warning-light;
    }

    &--tag {
      color: $yellow;
      background: $warning-light;
    }
  }

  &-red {
    background: $red;

    &--light {
      color: $gray-800;
      background: $danger-light;
    }

    &--tag {
      color: $red;
      background: lighten($red, 30%);
    }
  }

  &-green {
    background: $green;

    &--light {
      color: $gray-800;
      background: lighten($green, 32%);
    }

    &--tag {
      color: #419181;
      background: #f0f8f7;
    }
  }

  &-blue {
    background: $blue;
    color: #fff;

    &--light {
      color: $gray-800;
      background: lighten($blue, 30%);
    }
    &--tag {
      color: $blue;
      background: lighten($blue, 30%);
    }
  }

  // pink
  &-magenta {
    background: $pink;

    &--light {
      color: $gray-800;
      background: lighten($pink, 23%);
    }

    &--tag {
      color: $pink;
      background: lighten($pink, 23%);
    }
  }

  &-gray {
    background: $gray-300;
    color: $gray-800;

    &--light {
      color: $gray-800;
      background: $gray-100;
    }

    &--tag {
      color: $gray-800;
      background: $gray-100;
    }
  }

  &-secondary {
    background: $gray-600;
    color: $white;
  }

  &.active {
    background: $primary;
    color: $white;
  }
}
