.slick-slider {
  .update-user-info-tags {
    margin-right: 10px;
  }

  .form-group {
    margin: 0;
  }

  .slick-prev {
    left: 0;
    z-index: 1;
    width: 30px;
    height: 30px;

    background: #ffffff;
    /* Popup */

    border-radius: 20px;
    padding: 8px 8px;

    &:before {
      content: '\e903';
      font-family: 'fusetalent' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      color: $green;
      font-weight: 600;
      font-size: 15px;
    }

    &.slick-disabled {
      display: none !important;
    }

    &:hover {
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
    }
  }

  .slick-next {
    right: 0;
    z-index: 1;

    width: 30px;
    height: 30px;

    background: $white;
    /* Popup */
    border-radius: 20px;
    padding: 8px 8px;

    &.slick-disabled {
      display: none !important;
    }

    &:before {
      content: '\e903';
      font-family: 'fusetalent' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      color: $green;
      font-weight: 600;
      font-size: 15px;

      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      display: block;
    }
    &:hover {
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
    }
  }
}
