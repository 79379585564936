.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: $primary-light-2;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  font-size: $h3-font-size;
  color: $white;
  text-align: center;
  position: relative;
  flex-shrink: 0;

  &-sq {
    border-radius: 4px;
  }

  img {
    max-width: 100%;
  }

  span {
    @include vertical-align;
    display: block;
  }

  &-xss {
    width: 22px;
    height: 22px;
    font-size: $small-font-size;
  }

  &-xs {
    width: 32px;
    height: 32px;
    font-size: $h6-font-size;
  }

  &-sm {
    width: 40px;
    height: 40px;
    font-size: $h4-font-size;
  }

  &-lg {
    width: 80px;
    height: 80px;
    font-size: $h2-font-size;
  }

  &-xl {
    width: 112px;
    height: 112px;
    font-size: $h1-font-size;
  }

  &--list {
    display: inline-block;
    vertical-align: middle;
    margin-top: 5px;

    .avatar {
      z-index: 1;
      margin-left: -10px;
      border: 1px solid $white;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
