.accordian {
  &-multi {
    > .card {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 1rem;
      border: 1px solid $gray-300;
      box-shadow: none;

      &:not(:last-of-type) {
        border-bottom: 1px solid $gray-300;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:not(:first-of-type) {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      .card-header {
        background: none;
        cursor: pointer;

        .h-block {
          visibility: hidden;
          opacity: 0;
        }

        .ft-angle {
          display: inline-block;
          font-weight: 800;
          vertical-align: middle;
          transition: all 300ms ease-out;
        }
      }

      .card-body {
        .comments {
          border-left: 3px solid $gray-500;
          padding: 12px 15px;
          margin: 12px 0 20px;

          &--box {
            margin-bottom: 2rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .show,
      .collapsing {
        & ~ .card-header .ft-angle {
          @extend .ft-rotate-180;
        }
      }

      &:hover {
        .card-header {
          .h-block {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
