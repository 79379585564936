.popup {
  &-wrapper {
    position: absolute;

    display: inline-block;

    &:hover {
      .popup-body {
        z-index: 10;
        opacity: 1;
        visibility: visible;
        transform: translate(0, -20px);
        transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
      }
    }
  }

  &-body {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    // left: 13px;
    right: 100%;
    transform: translate(0, -40px);
    background-color: $white;
    margin-top: 12px;
    padding: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    width: 400px;
    border-radius: 6px;
    top: -50px;

    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      right: calc(50% - 10px);
      top: -8px;

      transition-duration: 0.3s;
      transition-property: transform;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .badges {
      // max-width: 200px;
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}

.resume {
  &-card {
    @extend .border;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal {
    &-header {
      background-color: $primary-dark-1;
      color: $white;
      padding: 12px 16px;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }

    &-body {
      padding: 0;

      .form--container .form__body {
        height: calc(100% - 55px);
        min-height: calc(100% - 55px);
        @extend .scrollbar;
      }

      .resume-download {
        position: absolute;
        right: 0;
        margin-right: 16px;
      }
    }
  }
}

.table-responsive {
  @extend .scrollbar;
}
