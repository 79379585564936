.alert {
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  margin: 0;
  color: $primary-dark-2;

  &-info {
    background: $info-light;
    border-color: $info-light;
    border-left: 4px solid $info;
  }

  &-blue {
    background: $accent-100;
    border-color: $accent-100;
    border-left: 4px solid $blue;
  }

  &-success {
    background: $success-light;
    border-color: $success-light;
  }

  &-danger {
    background: #f6d3d2;
    border-left: 6px solid $danger;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;

    i {
      color: inherit;
      font-weight: 800;
    }
  }
}
