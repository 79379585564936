.h {
  &-100 {
    height: 100vh;
    overflow: hidden;
  }
  &-80 {
    height: calc(100vh - 150px);
    @extend .scrollbar;
  }
  &-60 {
    height: calc(100vh - 180px);
    @extend .scrollbar;
  }
  &-5 {
    height: calc(100vh - 255px);
    @extend .scrollbar;
  }
  &-45 {
    height: calc(100vh - 306px);
    @extend .scrollbar;
  }
}

.mh {
  &-60 {
    max-height: calc(100vh - 292px);
    @extend .scrollbar;
  }
}
