.form {
  &--widget {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    padding: 12px 0;
    overflow: hidden;
  }

  &-widget {
    display: flex;
    align-items: center;
    justify-content: end;

    .item {
      color: $gray-500;
      font-weight: 400;
      font-size: $h5-font-size;

      .num {
        width: 26px;
        height: 26px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        border-radius: 50%;
        padding: 1px;
        background-color: $gray-200;
        text-align: center;
      }

      .icon {
        width: 26px;
        height: 26px;
        display: none;
        vertical-align: middle;
        margin-right: 10px;
        border-radius: 50%;
        padding: 1px 2px;
        text-align: center;
        background-color: $success;
        color: $white;
      }

      &.active {
        color: $primary;

        .num {
          background-color: $primary;
          color: $white;
        }
      }

      &.done {
        color: $gray-800;

        .icon {
          display: inline-block;
        }

        .num {
          background-color: $success;
          color: $white;
          display: none;
        }
      }
    }
  }
}

.widget {
  &__item {
    position: relative;
    cursor: default;

    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid $primary-light-2;
      background: $primary-light-1;
      bottom: -12px;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 4px;
      right: 98%;
      background: $primary-light-2;
      bottom: -9px;
      /* "to left" / "to right" - affects initial color */
      background: linear-gradient(to left, $primary-light-2 50%, $primary 50%) right;
      background-size: 200%;
      transition: 300ms ease-out;
      width: 270px;
      background-position: right;
      border-radius: 10px;
    }

    &:last-child {
      &:before,
      &:after {
        right: 0;
      }
    }

    &:first-child:after {
      display: none;
    }

    &.active {
      color: $primary;

      &:before,
      &:after {
        background: currentColor;
      }

      &:after {
        z-index: 1;
        background-position: left;
      }
    }
  }

  &__block {
    height: 100%;
    padding: 34px 25px 0;

    .item {
      color: $gray-600;
      display: flex;
      align-items: center;
      font-size: $font-size-lg;
      padding-bottom: 50px;
      position: relative;

      .icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        text-align: center;
        border: 1px solid currentColor;
        margin-right: 10px;
        line-height: 32px;
        position: relative;

        .txt {
          display: none;
        }

        i {
          color: inherit;
          font-size: 38px;
          padding: 0;
          position: absolute;
          left: -4px;
          top: -4px;
          display: none;
        }

        &:after {
          content: '';
          position: absolute;
          width: 11px;
          height: 11px;
          background: $gray-800;
          border-radius: 11px;
          left: 10px;
          top: 10px;
        }
      }

      .text {
        @extend .text-truncate;
      }

      &.active {
        color: $primary;

        .icon {
          .txt {
            display: block;
          }

          &:after {
            display: none;
          }
        }
      }

      &.done {
        .icon {
          i {
            display: block;
          }

          &:after {
            display: none;
          }
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 15px;
        border-left: 2px dashed currentColor;
        height: 40px;
        top: 35px;
      }

      &:last-child {
        padding: 0;

        &:after {
          display: none;
        }
      }
    }
  }
}

.form {
  &--container {
    height: calc(100vh - 62px);
    @extend .scrollbar;

    .role-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2px;
    }

    .form__body {
      min-height: calc(100% - 64px);

      .upload {
        display: flex;
        justify-content: space-between;
        max-width: 670px;
        &-resume {
          margin-top: 0;
          padding: 1.5rem;
          border: 1px dashed $gray-300;
          background-color: $bg-color-1;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 270px;
          height: auto;
          > input {
            cursor: pointer;
            width: 100%;
            opacity: 0;
          }
          > span {
            position: relative;
            z-index: 1;
          }
          .drag-label {
            position: relative;

            .file-type {
              font-size: $font-size-sm;
              position: absolute;
              left: 0;
              margin-top: 23px;
              margin-left: -15px;
              text-align: center;
              width: 188px;
            }
          }
          label {
            cursor: pointer;
          }
          img {
            height: 80px;
            margin-bottom: 16px;
          }
          .upload-folder {
            height: 70px;
          }
        }
      }

      .uploaded-files {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .title {
          display: flex;

          div:nth-child(1) {
            height: 24px;
            margin-top: 2px;
          }
          div:nth-child(2) {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            span:nth-child(2) {
              font-size: $font-size-sm;

              .dot {
                height: 3px;
                width: 3px;
                margin-bottom: 2px;
                border-radius: 50%;
                background-color: $text-muted;
                display: inline-block;
              }
            }
          }
        }
      }

      .uploading {
        opacity: 0.5;
      }
      .confirm-job {
        .job-title {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          padding: 0px 16px;
          margin-bottom: 16px;
          .title {
            display: flex;
            flex-direction: column;
          }
        }
        .resume-title {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          padding: 16px;
          border: 1px solid $gray-100;
          box-sizing: border-box;
          border-radius: 4px;
          margin-bottom: 16px;
          .title {
            display: flex;
            flex-direction: column;
          }
        }

        .resume--item {
          padding: 16px 16px 6px 16px;
          border: 1px solid $gray-400;
          box-sizing: border-box;
          border-radius: 4px;
          margin-bottom: 16px;
        }
      }
    }

    .form__footer {
      background: rgba(255, 255, 255, 0.75);
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      position: sticky;
      bottom: 0;
      z-index: 1;
    }
  }

  &__widget {
    display: flex;
    width: 520px;
    max-width: 100%;

    .item {
      flex-grow: 1;
      color: $white;
      position: relative;

      &:after {
        content: '';
        height: 1px;
        background: $white;
        position: absolute;
        top: 50%;
        left: 21px;
        right: 0;
        top: 10px;
      }
      .num {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 4px;
        width: 22px;
        height: 22px;
        padding: 0px 2px;
        border: 1px solid $white;
        border-radius: 50%;
        text-align: center;
        position: relative;
        font-size: $small-font-size;
      }

      .text {
        display: block;
        line-height: 1.4;
      }
      &.active .num {
        background: $white;
        color: $gray-900;
      }
      &:last-child {
        flex-grow: 0;
        &:after {
          display: none;
        }
      }
      &.done .num {
        position: relative;
        background: $green;

        &:before {
          content: '';
          position: absolute;
          background: $green;
          border-radius: 50%;
          background-image: url("data:image/svg+xml,%3Csvg width='13' height='9' viewBox='0 0 13 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.6236 1.0625L4.19703 7.93673L0.821289 4.81208' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: 12px;
          background-position: center;
          left: 0px;
          top: 0px;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

fieldset.box {
  border-radius: 8px;
  padding: 1.5rem 2rem;
  border: 1px solid $gray-300;
}

.modified {
  box-shadow: 0 0 0 2px #f6c750;
}
