.res-cont {
  width: 100vw;
  height: calc(100vh - 62px);

  .project {
    padding: 12px 0;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 6;

    &__requied {
      display: inline-flex;
      vertical-align: middle;
      margin-right: 12px;
      font-size: $font-size-sm;
      text-align: left;
    }
  }

  .left {
    padding: 12px 15px;
    width: 245px;
    min-width: 245px;
    // height: calc(100vh - 62px - 72px - 59px);
    height: calc(100vh - 62px - 72px);
    @extend .scrollbar;
    border-right: 1px solid #dbdbdf;
  }

  .res {
    &--req {
      padding: 8px 16px;
      background: $bg-color;
      border-radius: 8px;
    }
    &--item {
      border-radius: 4px;
      border: 1px dashed #dbdbdf;
      // padding: 14px 8px;
      filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
      opacity: 0.6;
      margin-bottom: 16px;
      cursor: pointer;

      .ic-warning {
        width: 18px;
        height: 18px;
        text-align: center;
        padding: 0;
        margin: 0;
      }

      .info {
        font-size: 10px;
      }

      &.active,
      &:hover {
        border: 1px solid $primary-light-2;
        opacity: 1;

        .border-top {
          border-color: $primary-light-2 !important;
        }
      }
    }
  }

  .right {
    padding: 0;
    width: calc(100% - 240px);
    // height: calc(100vh - 62px - 72px - 59px);
    height: calc(100vh - 62px - 72px);
    @extend .scrollbar;

    &-profile {
      padding: 0;
      width: calc(100% - 240px);
    }

    .post__info {
      box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
      padding: 12px 15px;
      // position: relative;
      z-index: 5;
      position: sticky;
      top: 0;
      background: $white;

      .info--more {
        position: absolute;
        padding: 0 15px;
        top: 89%;
        box-shadow: 0 12px 17px rgb(0 0 0 / 10%);
        left: 0;
        right: 0;
        z-index: 1;
        background: $white;
        overflow: hidden;
        max-height: 0;

        @include transition-css(all, 300ms, ease-in-out);

        &.show {
          max-height: 400px;

          @extend .scrollbar;
        }
      }
    }
  }

  .form--footer {
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.1);
    background-color: $white;
    display: block;
    text-align: right;
    padding: 12px 15px;
    z-index: 1;
    position: relative;
  }

  &-req {
    width: 16px;
    height: 16px;
    background: $orange;
    font-size: 10px;
    border-radius: 50px;
    display: inline-block;
    line-height: 16px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-left: 12px;
  }
}

.ov-status {
  .item {
    display: flex;
    font-weight: $font-family-base;

    &:first-child {
      border-bottom: 1px solid #dbdbdf;
      font-weight: 600;
    }

    > div {
      padding: 12px 0;

      &:first-child {
        min-width: 190px;
      }

      &:nth-child(2) {
        min-width: 182px;

        h5 {
          text-decoration: underline;
          font-weight: 600;
          margin: 0;
        }
      }
    }
  }
}

.rts-block {
  border-radius: 4px;
  border: 1px dashed #dbdbdf;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.16));
  opacity: 0.8;
  margin: 7px 0 12px;
  cursor: pointer;

  &.active,
  &:hover {
    border: 1px solid $primary-light-2;
    opacity: 1;

    .border-top {
      border-color: $primary-light-2 !important;
    }
  }
}
