.multi-progress {
  overflow: hidden;

  .progress-bar {
    border-radius: 0;
  }
}

.progress-bar {
  //   border-radius: 0;

  &.bg-blue {
    background: lighten($blue, 25%);
  }
  &.bg-magenta {
    background: lighten($pink, 25%);
  }
  &.bg-purple {
    background: lighten($purple, 25%);
  }
  &.bg-orange {
    background: lighten($orange, 25%);
  }
  &.bg-red {
    background: lighten($red, 25%);
  }
  &.bg-green {
    background: lighten($green, 25%);
  }
  &.bg-yellow {
    background: lighten($yellow, 25%);
  }
  &.bg-gray {
    background: lighten($gray-600, 25%);
  }
}
