html,
body {
  font-size: 14px;
}

.scrollbar {
  --scrollbarBG: #cfd8dc;
  --thumbBG: $green;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  overflow: auto !important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cdcdd3;
    // background-image: -webkit-gradient(
    //   linear,
    //   0 0,
    //   0 100%,
    //   color-stop(0.5, rgba(255, 255, 255, 0.2)),
    //   color-stop(0.5, transparent),
    //   to(transparent)
    // );
  }
}
.pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}

.text-primary-light {
  color: $primary-light-1;
}

.text-green {
  color: $green;
}

.ic-warning {
  border-radius: 50%;
  background-color: $warning-light;
  color: $warning;
  padding: 1px 1px;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 2px;
  cursor: pointer;
  display: inline-block;

  i {
    font-weight: 800;
    line-height: $font-size-sm;
    font-size: $font-size-sm;
    margin-top: 2px;
    display: block;
  }
}

.ft-xs {
  font-size: $xs-font-size;
}

.sticky {
  &-bottom {
    position: sticky;
    bottom: 0;
  }
}

.title {
  &-border {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      border-bottom: 1px solid $gray-300;
      bottom: 5px;
      z-index: -1;
    }
    span {
      padding-right: 5px;
      background: white;
    }
  }

  &-divider {
    position: relative;
    z-index: 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      border-bottom: 1px solid $gray-300;
      bottom: 47%;
      z-index: -1;
    }
    span {
      padding: {
        right: 5px;
        left: 2px;
      }
      background: white;
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}

.bg {
  &-color {
    background-color: $bg-color;
  }
  &-light-success {
    background-color: #f0f8f7;
  }
}

.quick-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin: 0.5rem 0 0.85rem;
  opacity: 1;
  max-height: 1000px;

  > div {
    flex-shrink: 0;
  }
}

.layout {
  &-flex {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}

.overflow-visible {
  overflow: visible !important;
}
