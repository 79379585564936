.modal {
  &-content {
    padding: 16px 0;
  }

  &-header {
    padding: 0px 16px 16px;
  }

  &-body {
    overflow: auto;
    padding: 16px 16px 0 16px;
    @extend .scrollbar;

    .form--container {
      padding: 16px 0 0;
      height: calc(100vh - 52px);

      .form__body {
        padding: 0 16px;
        height: auto;
        min-height: calc(100% - 60px);
      }
      .form__footer {
        box-shadow: none;
        padding: 12px 16px 12px;
        text-align: right;
        z-index: 4;
      }
    }
  }

  &-footer {
    padding: 16px 16px;
  }

  &-01 {
    .modal {
      &-header {
        background-color: $primary;
        color: white;
        .close {
          color: white;
        }
      }
    }
  }
  &.left {
    .modal-dialog {
      left: -320px;
      -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
      -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
      -o-transition: opacity 0.3s linear, left 0.3s ease-out;
      transition: opacity 0.3s linear, left 0.3s ease-out;

      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
    }

    &.show {
      .modal-dialog {
        left: 0;
      }
    }
  }

  &.right {
    .modal {
      &-dialog {
        margin: 0 0 0 auto;
        max-width: 600px;
        height: 100vh;
        width: 100%;
        right: -600px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;

        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);

        &.modal-sm {
          max-width: 429px !important;
          right: -429px;
        }
      }

      &-content {
        @extend .scrollbar;
        height: 100vh;
        overflow: auto;
        border-radius: 0;
        border: 0;
        padding: 0;
      }

      &-header {
        padding: 12px 16px;
        border-radius: 0;
      }
    }

    &.show {
      .modal-dialog {
        right: 0;
      }
    }
  }

  &.w-full {
    padding : {
      left: 0 !important;
      bottom: 0 !important;
    }
    .modal-dialog {
      max-width: 100%;
      margin-bottom: 0;
    }

    .modal-content {
      padding-bottom: 0;
    }
  }

  &.bottom {
    .modal {
      &-dialog {
        margin: 0 0 0 0;
        height: 90vh;
        display: flex;
        margin-top: 10vh;
        width: 100%;
        max-width: 100%;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;

        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
      }
    }
  }

  &.get__strates {
    text-align: center;

    .modal-content {
      overflow: hidden;
    }

    .modal-body {
      border-top: 10px solid $primary;
    }
  }
}
