aside.light {
  width: 100%;
  display: block;
  padding: 0;
  background: $white;
  max-width: 249px;
  min-width: 10px;
  @include transition-css(all, ease-in-out, 250ms);

  border-right: 1px solid $gray-300;

  .toggle {
    display: none;
    cursor: pointer;

    &--icon {
      display: none;
      cursor: pointer;
      color: $white;
    }
  }

  .logo {
    &--lg {
      display: block;
    }

    &--sm {
      display: none;
    }
  }

  .sidebar {
    &--block {
      position: relative;

      .toggle-md {
        position: absolute;
        top: 15px;
        right: -10px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $bg-color;
        border: 1px solid $primary-light-2;
        cursor: pointer;
        padding: 3px 4px;
        z-index: 1;
        color: $primary;
        font-size: $font-size-sm;

        @include transition-css(all, ease-in-out, 300ms);

        span {
          width: 6px;
          height: 6px;
          display: block;
          margin: 0px 4px;
          font-weight: $font-weight-bold;
          @extend .ft-rotate-90;
        }

        &:hover {
          background-color: $primary;
          color: $white;
        }
      }
    }
    &__content {
      height: calc(100vh - 63px);
      padding: 8px 8px;
      @extend .scrollbar;
    }
  }

  .nav {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &--item {
      padding: 17px 10px 17px 12px;
      font-size: $font-size-base;
      color: $gray-900;
      display: flex;
      align-items: center;
      border-radius: 0px;
      margin: 10px 0px;
      width: 100%;
      cursor: pointer;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .text {
        margin-left: 10px;
        width: calc(100% - 31px);
        @include text-truncate;
        @include transition-css(all, ease-in-out, 300ms);
      }

      &.active {
        background: $bg-color;
        color: $primary;
        font-weight: $font-weight-bold;
        position: relative;

        &:after {
          content: '';
          width: 4px;
          background-color: $primary;
          position: absolute;
          left: 0;
          top: 14px;
          bottom: 14px;
        }
      }

      &:hover {
        // background: $bg-color;
        color: $primary;
        text-decoration: none;
        font-weight: $font-weight-bold;
      }

      &.disabled {
        opacity: 0.8;
        pointer-events: none;
      }
    }

    &__dropdown {
      border-radius: 4px;
      overflow: hidden;
      max-height: 61px;

      @include transition-css(all, 300ms, ease-in-out);

      .nav--item:first-child:hover {
        font-weight: $font-weight-normal;
        color: inherit;
        background-color: $bg-color;
      }

      &.show {
        background-color: $bg-color;
        max-height: 400px;
      }
    }
  }

  &.sidebar__sm {
    max-width: 60px;

    .sidebar {
      &--block {
        .toggle-md {
          @extend .ft-rotate-180;
          span {
            display: block;
          }
        }
      }
    }

    .nav__dropdown {
      background: $white;
      .nav--item:first-child {
        display: none;
      }
    }

    .nav--item {
      .text {
        margin-left: 0px;
        max-width: 1px;
        overflow: hidden;
      }
    }
  }
}

.main-menu {
  padding: 16px 16px;
  border-radius: 4px;
  width: 644px;
  max-width: 100vw;

  .nav__item {
    display: inline-block;
    width: 50%;
    padding: 16px 16px;

    .title {
      margin: 4px 0;
      display: block;
      font-size: $h4-font-size;
      font-weight: $font-weight-bold;
    }

    &:hover {
      text-decoration: none;
      background: $bg-color;
    }

    &.active {
      background: $bg-color;
    }
  }
}
