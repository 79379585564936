.range {
  &.horizontal-slider {
    position: relative;
    height: 2px;
    width: 100%;
    margin: 15px 0;

    .tracker {
      top: 0px;
      bottom: 0px;
      background: rgb(221, 221, 221);
      border-radius: 999px;

      &-1 {
        background: $primary;
      }
    }

    .slider-thumb {
      cursor: pointer;
      width: 15px;
      height: 15px;
      background: $primary;
      border-radius: 50px;
      top: -7px;
      z-index: auto !important;

      .thumb--text {
        position: absolute;
        top: 15px;
        right: 0;
        left: auto;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}
