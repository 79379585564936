.cts-filter {
  display: block;
  margin: 8px 0 0;
  color: $primary;

  .cts-nav {
    display: flex;
    padding: 8px 8px;
    align-items: center;
    cursor: pointer;
    .text {
      width: calc(100% - 20px);
      span {
        color: $red;
      }
    }

    &.active {
      background-color: $primary;
      color: $white;
      .text {
        span {
          color: $white;
        }
      }
    }
  }
}
.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  // max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  li {
    padding: 0.5rem;
    &:hover {
      background-color: #deebff;
      color: $black;
      cursor: pointer;
      font-weight: 700;
    }
  }
  .suggestion-active {
    background-color: #deebff;
    color: $black;
    cursor: pointer;
    font-weight: 700;
  }
}
