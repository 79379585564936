.breadcrumb {
  background: transparent;

  padding: 16px;
  &-item {
    a {
      color: $gray-700;
      font-size: $font-size-lg;
      line-height: 24px;
    }
    &.active {
      color: $primary;
    }
  }
}
