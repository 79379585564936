.list {
  &-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      margin-bottom: 0.5rem;
      display: flex;
      color: $gray-800;
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        background-color: $dark;
        border-radius: 50%;
        margin-right: 0.5rem;
        flex-shrink: 0;
        margin-top: 0.6rem;
      }
    }
  }
}

.draggable {
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin: 0 -1rem;

    .hover-btn {
      opacity: 0;
    }
    &:hover {
      background-color: $bg-color-1;
      .hover-btn {
        opacity: 1;
      }
    }
  }
}
